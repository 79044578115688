import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { AuditForm, HeaderHero, NavBarColorSwitcher, Spacer, WrapperBody } from "components";
export const _frontmatter = {
  "title": "Website audit",
  "navBarColor": "light"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <HeaderHero title="Website audit" subtitle="Get an insight from our specialist" button={{
      children: "Apply now",
      to: "#form"
    }} mdxType="HeaderHero" />
    <WrapperBody mdxType="WrapperBody">
	<NavBarColorSwitcher color="dark" mdxType="NavBarColorSwitcher">
		<h2>
			Take advantage of this opportunity to take your website to the next
			level!
		</h2>
		<p>
			Our expert will give you an overview of your website's content,
			structure, design, and functionality and provide a valuable list of
			detected irregularities to help you optimize your website.
		</p>
		<p>
			This offer won't last forever, so claim your free check now! Apply
			below before it's too late.
		</p>
		<Spacer height={100} responsive={{
          md: 80,
          sm: 60
        }} mdxType="Spacer" />
		<AuditForm formId="u4PM8LWl" confirmationMessage="Heck. You just applied!" privacyPolicyURL="https://departmentofweb.com/privacy-policy" redirectURL="https://departmentofweb.com/audit/thanks/" mdxType="AuditForm" />
	</NavBarColorSwitcher>
    </WrapperBody>
    <Spacer height={180} responsive={{
      md: 140,
      sm: 100
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      